<template>
    <v-container>
        <div align="left">
            <v-icon class='px-3' color='primary'>mdi-web</v-icon> 
            <v-btn-toggle
                color='primary'
                v-model="lang_de"
                class="pa-2"> 
                <v-btn small>
                    EN
                </v-btn>      
                <v-btn small>
                    DE
                </v-btn>
            </v-btn-toggle>
        </div>
        <v-container class="grey lighten-5 ">
            <p v-if="!lang_de" class="linebreak text-justify" >
            We are Elena and Jakob. We travel with our red T5 for one year through Europe. We have built the interior ourselves, and specially designed for all our needs: We can fit climbing, camping, kite surfing gear and even a long board.  <br/>
            We have a very comfortable bed, which at the same time serves as a couch. We have two desks, a sort-of shower, and two taps with drinking water. To charge our devices and run our fridge we have a built-in secondary battery that get charged by our 200W solar panels. <br/>
            There we go, everything we need to live a happy independent life.
             <br/> <br/>
            ...  More pictures are following ... </p>
            <p v-if="lang_de" class="linebreak text-justify" >
            Wir sind Elena und Jakob. Wir reisen mit unserem roten T5 ein Jahr lang durch Europa. Wir haben den Bus selbst gebaut, und speziell auf all unsere Bedürfnisse zugeschnitten: Wir können Kletter-, Camping-, Kitesurf-Ausrüstung und sogar ein Longboard unterbringen. <br/>
            Wir haben ein sehr bequemes Bett, das gleichzeitig als Couch dient. Wir haben zwei Schreibtische, eine "Dusche" und zwei Wasserhähne mit Trinkwasser. Um unsere Geräte aufzuladen und unseren Kühlschrank zu betreiben, haben wir eine eingebaute Sekundärbatterie, die von unseren 200W Solarmodulen aufgeladen wird. <br/>
            Wie du siehst haben wir alles was wir brauchen, um ein schönes freies Leben zu führen. <br/>  <br/>

            ... Mehr Bilder folgen ...
            </p>
        </v-container>

      <!-- Gallery  -->
      <v-row align="center" justify="center" class="pb-5">
        <v-col
          v-for="(p,index) in pictures"
          :key="index"
          class="d-flex child-flex"
          cols="12"
          :sm="colnumber"
          >
          <v-img
            contain
            :lazy-src="p.thumbUrl"
            height="400"
            width="300"
            :src="p.url"
            @click="OverlayIndex=index+1"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-col> 
      </v-row> 
    </v-container>

</template>

<script>
export default {
    name: 'About',
    data: () => ({
        lang_de: true,
        pictures: [          
            {thumbUrl: '',
            url: 'http://files.elena-jakob.eu/IMG_0485.JPG'},
            {thumbUrl: '',
            url: 'http://files.elena-jakob.eu/DSC07569.jpg'},
            {thumbUrl: '',
            url: 'http://files.elena-jakob.eu/DSC05802.jpg'},
        ]

    }),
    computed: {
        colnumber() {
            return 4
        },
        language() {
            if (this.lang_de) {
            return 'deutsch'
            } else {
            return 'english'
            }
      },  
    }
}    
</script>
